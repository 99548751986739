import React, { Fragment, PureComponent } from "react"

import { NavLink } from "react-router-dom"
import { Icon } from "icon-library"

import S from "./Settings.css"
export class Settings extends PureComponent {
  static defaultProps = {
    open: false,
    setContext: () => {},
  }

  state = {
    open: this.props.open,
  }

  toggle = () => {
    const open = !this.state.open
    this.setState({ open })
    this.props.setContext({ showHeaderSubMenu: open })
  }

  logout = () => {
    this.props.Auth.clearSession()
  }

  render() {
    return (
      <div className={`${this.props.className} ${S.links}`}>
        {this.state.open && (
          <Fragment>
            <NavLink activeClassName={S.active} to="/hp/prescription">
              Prescription
            </NavLink>
            <NavLink activeClassName={S.active} to="/hp/profile">
              Personal Info
            </NavLink>
            <NavLink activeClassName={S.active} to="/hp/communication">
              Communications
            </NavLink>
            <NavLink
              activeClassName={S.active}
              exact
              to="/"
              onClick={this.logout}
            >
              Log Out
            </NavLink>
          </Fragment>
        )}
        <span
          className={`${S.toggle} ${this.state.open ? S.inactiveName : ""}`}
          onClick={this.toggle}
        >
          {this.props.firstName}
          <Icon name="cog" className={S.cog} />
        </span>
      </div>
    )
  }
}

export default Settings
