import React from "react"
import ReactDOM from "react-dom"
import history from "lib/history"
import { Router } from "react-router-dom"
import { AppContainer } from "react-hot-loader"
import Auth from "lib/auth"
import * as AxiosConfig from "lib/axios"
import { htmlString } from "icon-library"

import "lib/googleAnalytics"

import "./styles/vendor.css"

import App from "./App"
import "./styles/global.css"

function injectSvgSheet(htmlString) {
  document.body.insertBefore(
    document.createRange().createContextualFragment(htmlString),
    document.body.firstChild
  )
}

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Router history={history}>
        <Component />
      </Router>
    </AppContainer>,
    document.getElementById("root")
  )
}

AxiosConfig.setDefaults()
if (Auth.isValid()) {
  AxiosConfig.setAuthHeaders()
}

injectSvgSheet(htmlString)
render(App)

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => {
    render(App)
  })
}
