import React, { PureComponent } from "react"

import IntroFlow from "./IntroFlow"

const BANNERS = [{ bannerText: "Placeholder" }]

import S from "./DashboardCarousel.css"
export class MobileDashboardCarousel extends PureComponent {
  state = {
    banner: null,
  }

  static getDerivedStateFromProps({ bannerData }, { banner }) {
    if (banner === null) {
      let banners = BANNERS
      banners.splice(0, bannerData.length, ...bannerData)

      return {
        banner: banners[0],
      }
    }
    return null
  }

  render() {
    const { bannerText, mobileImage } = this.state.banner
    return this.props.introFlowActive ? (
      <IntroFlow className={S.contentEntry} {...this.props} />
    ) : (
      <div className={S.mobileBanner}>
        <div className={S.bannerText}>{bannerText}</div>
        {mobileImage && <img src={mobileImage} className={S.img} />}
      </div>
    )
  }
}

export default MobileDashboardCarousel
