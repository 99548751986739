import React from "react"
import Auth from "lib/auth"
import { withContent } from "content-library"

import { Redirect, Route } from "react-router-dom"
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary"

const staticRoutes = ["/hp/rules", "/hp/support", "/hp/terms", "/hp/privacy"]

export function toPublicRoute(path) {
  if (staticRoutes.indexOf(path) === -1) return "/"

  return path.split("hp/").join("")
}

export class PrivateRoute extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { location, shouldUpdate } = this.props
    const locationChange = nextProps.location !== location

    return shouldUpdate
      ? shouldUpdate(nextProps, this.props) || locationChange
      : true
  }

  render() {
    let { component: Component, contentKeys, ...rest } = this.props
    Component = withContent(Component, contentKeys)
    return (
      <Route
        {...rest}
        render={(props) =>
          Auth.isValid() ? (
            <ErrorBoundary
              location={props.location}
              errorMsg={`Something went wrong with a server call for this route. Please see your network tab and replay the request for further details.`}
            >
              <Component {...rest} {...props} />
            </ErrorBoundary>
          ) : (
            <Redirect
              to={{
                pathname: toPublicRoute(props.location.pathname),
                state: props.location.pathname,
              }}
            />
          )
        }
      />
    )
  }
}

export default PrivateRoute
