import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import * as contentKeys from "lib/routeContentKeys"
import { tipHistoryUpdater } from "lib/shouldUpdates"

import { withContent } from "content-library"
import {
  DailyTipHistory,
  Leaderboard,
  Notifications,
  OptOut,
  PointsHistory,
  PrivacyPolicy,
  QuizHistory,
  RxVerification,
  Rules,
  Support,
  SurveyHistory,
  TermsOfUse,
  UserProfile,
  TipsToWin,
  WeeklyDrawingHistory,
} from "component-library"

import AppStateComponent, {
  withAppState,
} from "components/providers/AppStateComponent"
import StreakStateComponent from "components/providers/StreakStateComponent"

import ContentLoader from "components/ContentLoader/ContentLoader"
import PrivateRoute from "components/PrivateRoute/PrivateRoute"
import PrivateHeader from "components/Header/PrivateHeader"
import Dashboard from "components/Dashboard/Dashboard"
import RewardsMall from "components/RewardsMall/RewardsMall"
import Landing from "components/Landing/Landing"
import Sidebar from "components/Sidebar/Sidebar"
import Footer from "components/Footer/Footer"
import NoMatch from "components/NoMatch/NoMatch"
import AutoSignOut from "components/AutoSignOut/AutoSignOut"
import LoadingModal from "components/LoadingModal/LoadingModal"

function RedirectToDashboard() {
  return <Redirect to="/hp/dashboard" />
}

const PrivateFooter = withAppState(withContent(Footer, "PrivateFooter"))
const Landing_ = withContent(Landing, contentKeys.landing)

import sampleLabel from "images/sample_label.png"
import S from "./App.css"
export class App extends Component {
  render() {
    return (
      <div className={S.app}>
        <ContentLoader>
          <Switch>
            <Route exact path="/hp" component={RedirectToDashboard} />
            <Route path="/hp">
              <AppStateComponent>
                <StreakStateComponent>
                  <div className={S.main}>
                    <PrivateRoute component={withAppState(PrivateHeader)} />
                    <div className={S.mainContent}>
                      <div className={S.mainPane}>
                        <Switch>
                          <PrivateRoute
                            path="/hp/dashboard"
                            component={Dashboard}
                            contentKeys={contentKeys.dashboard}
                          />
                          <PrivateRoute
                            path="/hp/quiz"
                            component={withAppState(QuizHistory)}
                            LoadingModal={LoadingModal}
                            contentKeys={contentKeys.quiz}
                          />
                          <PrivateRoute
                            path="/hp/survey"
                            component={withAppState(SurveyHistory)}
                            LoadingModal={LoadingModal}
                            contentKeys={contentKeys.survey}
                          />
                          <PrivateRoute
                            path="/hp/daily-tip"
                            component={withAppState(DailyTipHistory)}
                            contentKeys={contentKeys.tip}
                            shouldUpdate={tipHistoryUpdater}
                          />
                          <PrivateRoute
                            path="/hp/rewards-mall"
                            component={withAppState(RewardsMall)}
                            contentKeys={contentKeys.rewards}
                          />
                          <PrivateRoute
                            path="/hp/rules"
                            component={Rules}
                            contentKeys={"Rules"}
                          />
                          <PrivateRoute
                            path="/hp/support"
                            component={Support}
                            contentKeys={["Support", "SupportLink"]}
                          />
                          <PrivateRoute
                            path="/hp/terms"
                            component={TermsOfUse}
                            contentKeys="TermsOfUse"
                          />
                          <PrivateRoute
                            path="/hp/tips-to-win"
                            component={TipsToWin}
                            contentKeys="TipsToWin"
                          />
                          <PrivateRoute
                            path="/hp/privacy"
                            component={PrivacyPolicy}
                            contentKeys={"PrivacyPolicy"}
                          />
                          <PrivateRoute
                            path="/hp/leaderboard"
                            component={Leaderboard}
                            contentKeys="Leaderboard"
                          />
                          <PrivateRoute
                            path="/hp/communication"
                            component={Notifications}
                            contentKeys={contentKeys.communication}
                          />
                          <PrivateRoute
                            path="/hp/profile"
                            component={withAppState(UserProfile)}
                            contentKeys={contentKeys.profile}
                            lang={false}
                          />
                          <PrivateRoute
                            path="/hp/prescription"
                            component={RxVerification}
                            contentKeys={contentKeys.prescription}
                            placeholder={false}
                            sampleLabel={sampleLabel}
                          />
                          <PrivateRoute
                            path="/hp/monthly-points"
                            component={PointsHistory}
                            contentKeys="PointsHistory"
                          />
                          <PrivateRoute
                            path="/hp/weekly-drawing"
                            component={WeeklyDrawingHistory}
                            contentKeys="WeeklyDrawingHistory"
                          />
                          <PrivateRoute
                            path="/hp/opt-out"
                            component={OptOut}
                            contentKeys="OptOut"
                          />
                        </Switch>
                      </div>
                      <Sidebar />
                    </div>
                    <PrivateFooter className={S.authFooter} />
                    <AutoSignOut />
                  </div>
                </StreakStateComponent>
              </AppStateComponent>
            </Route>
            <Route exact path="/" component={Landing_} />
            <Route path="/:staticContent" component={Landing_} />
            <Route component={NoMatch} />
          </Switch>
        </ContentLoader>
      </div>
    )
  }
}

export default App
