import React, { PureComponent } from "react"
import { Redirect, withRouter } from "react-router-dom"
import Auth from "lib/auth"
import { toPublicRoute } from "components/PrivateRoute/PrivateRoute"

import { withApi } from "component-library"
import LoadingModal from "components/LoadingModal/LoadingModal"

const AppState = React.createContext(defaultState)

const defaultState = {
  appLoading: true,
  showHeaderSubMenu: false,

  // withApi lifecycle coverage
  getPointsLoading: false,
  getPrimaryLoading: false,
  getPrimaryStatusLoading: false,
  getQuizAvailableLoading: false,
  getStreakStatusLoading: false,
  getSurveyAvailableLoading: false,
  getTipAvailableLoading: false,
  getUserLoading: false,
}

// api functions via withApi to be shared with consumers
const propKeys = ["getPoints", "getStreakStatus", "getUser"]
export function getDefaultState(props) {
  return propKeys.reduce((acc, k) => ({ ...acc, [k]: props[k] }), defaultState)
}

// dynamic keys will continue to get replaced by Api values
const dynamicStateKeys = ["points", "primaryStatus", "streakStatus", "user"]
const initialStateKeys = [
  ...dynamicStateKeys,
  "primaryRx",
  "articleAvailable",
  "quizAvailable",
  "surveyAvailable",
]

// checks for reference equality as withApi does not merge, only replace
export function getNewState(props, prevState) {
  let keys = dynamicStateKeys
  if (!prevState) {
    prevState = {}
    keys = initialStateKeys
  }

  return keys.reduce((acc, k) => {
    if (props[k] !== undefined && props[k] !== prevState[k]) {
      return { ...acc, [k]: props[k] }
    } else return acc
  }, {})
}

// There's a small amount of state in the client that needs to stay in-sync;
// this component exposes its state to a react context. Otherwise this is the
// place to initialize data, as the private section of the app won't render
// until this component finishes its async.
//
export class AppStateComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ...getDefaultState(this.props),
      setContext: this.setContext,
    }
  }

  static getDerivedStateFromProps(
    {
      getPointsSuccess,
      getPrimaryStatusSuccess,
      getPrimarySuccess,
      getQuizAvailableSuccess,
      getStreakStatusSuccess,
      getSurveyAvailableSuccess,
      getTipAvailableSuccess,
      getUserSuccess,
      ...props
    },
    prevState
  ) {
    const dataReady =
      getPointsSuccess &&
      getPrimaryStatusSuccess &&
      getPrimarySuccess &&
      getQuizAvailableSuccess &&
      getStreakStatusSuccess &&
      getSurveyAvailableSuccess &&
      getTipAvailableSuccess &&
      getUserSuccess

    const initialLoadDone = prevState.appLoading && dataReady
    if (initialLoadDone) {
      return { appLoading: false, ...getNewState(props) }
    } else if (prevState.appLoading) {
      return null
    }

    const result = getNewState(props, prevState)
    if (Object.keys(result).length > 0) {
      return result
    } else {
      return null
    }
  }

  setContext = (changeset) => this.setState(changeset)

  render() {
    const { children, location } = this.props
    const { appLoading } = this.state

    return Auth.isValid() ? (
      <AppState.Provider value={this.state}>
        {appLoading ? <LoadingModal /> : children}
      </AppState.Provider>
    ) : (
      <Redirect
        to={{
          pathname: toPublicRoute(location.pathname),
          state: location.pathname,
        }}
      />
    )
  }
}

export function withAppState(Component) {
  const StatefulComponent = (props) => {
    return (
      <AppState.Consumer>
        {(contextProps) => <Component {...props} {...contextProps} />}
      </AppState.Consumer>
    )
  }
  StatefulComponent.displayName = Component.displayName

  return StatefulComponent
}

export default withApi(withRouter(AppStateComponent), [
  // shared
  // anything that awards points
  {
    name: "getPoints",
    path: "points/status",
    transform: (points) => ({ points }),
    bustCache: true,
  },

  // streak
  {
    name: "getStreakStatus",
    path: "statistics/current",
    transform: (streakStatus) => ({ streakStatus }),
    bustCache: true,
  },

  // unverified
  {
    name: "getPrimaryStatus",
    path: "prescriptions/primary/status",
    transform: (primaryStatus) => ({ primaryStatus }),
    bustCache: true,
  },

  // alias, firstName
  {
    name: "getUser",
    path: "users/current",
    transform: (user) => ({ user }),
    bustCache: true,
  },

  // init
  {
    name: "getPrimary",
    path: "prescriptions/primary",
    transform: (primaryRx) => ({ primaryRx }),
  },
  {
    name: "getTipAvailable",
    path: "articles/current/available",
    transform: (data) => data,
  },
  {
    name: "getSurveyAvailable",
    path: "surveys/current/available",
    transform: ({ surveyQuizAvailable: surveyAvailable }) => ({
      surveyAvailable,
    }),
  },
  {
    name: "getQuizAvailable",
    path: "quizzes/current/available",
    transform: ({ surveyQuizAvailable: quizAvailable }) => ({ quizAvailable }),
  },
])
