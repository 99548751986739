import React, { PureComponent } from "react"

import { Motion, spring } from "react-motion"
import { Icon } from "icon-library"
import IntroFlow from "./IntroFlow"

const BANNERS = [
  { bannerText: "Placeholder" },
  { bannerText: "Placeholder" },
  { bannerText: "Placeholder" },
]

const contentPosition = (n) => n * 100

const CYCLE_TIME = 7000
const POST_USER_TAB_CHANGE_DELAY = 30000
const START = 0
const END = 3

const BANNER_HEIGHT = 260
const ADHERENCE_HEIGHT = 210

const springConfig = { precision: 1 }
import S from "./DashboardCarousel.css"
export class DashboardCarousel extends PureComponent {
  state = {
    banners: null,
    tab: null,
  }

  static getDerivedStateFromProps({ bannerData, introFlowActive }, { tab }) {
    if (tab === null) {
      let banners = BANNERS
      banners.splice(0, bannerData.length, ...bannerData)

      return {
        tab: introFlowActive ? END : START,
        banners,
      }
    }
    return null
  }

  componentDidMount() {
    if (!this.props.introFlowActive) {
      this.initCycle()
    }
  }

  componentDidUpdate({ introFlowActive }) {
    if (!this.props.introFlowActive && introFlowActive) {
      this.cycle(true)
    }
  }

  componentWillUnmount() {
    this.cycleInterval && window.clearInterval(this.cycleInterval)
    this.cycleTimeout && window.clearTimeout(this.cycleTimeout)
  }

  cycle = (reset) => {
    if (reset) return this.setState({ tab: 0 })

    const { tab } = this.state
    if (tab === END) return

    let end = END
    if (!this.props.introFlowActive) {
      end -= 1
    }

    this.setState({ tab: tab === end ? START : tab + 1 })
  }

  initCycle = () => {
    this.cycleInterval && window.clearInterval(this.cycleInterval)
    this.cycleInterval = window.setInterval(this.cycle, CYCLE_TIME)
  }

  changeTab = ({ currentTarget }) => {
    this.setState({ tab: Number.parseInt(currentTarget.name, 10) })

    this.cycleInterval && window.clearInterval(this.cycleInterval)
    this.cycleTimeout && window.clearTimeout(this.cycleTimeout)

    this.cycleTimeout = window.setTimeout(
      this.initCycle,
      POST_USER_TAB_CHANGE_DELAY
    )
  }

  btnStyles = (index) => (this.state.tab === index ? S.active : "")

  renderControls() {
    const { adherenceNotification, introFlowActive } = this.props
    return (
      <div className={S.controls}>
        <button
          name="0"
          className={`${S.tabBtn} ${this.btnStyles(0)}`}
          onClick={this.changeTab}
        >
          Text Messages
        </button>
        <button
          name="1"
          className={`${S.tabBtn} ${this.btnStyles(1)}`}
          onClick={this.changeTab}
        >
          Tips to Win
        </button>
        <button
          name="2"
          className={`${S.tabBtn} ${this.btnStyles(2)}`}
          onClick={this.changeTab}
        >
          Stress Management
        </button>
        {introFlowActive && (
          <button
            name="3"
            className={`${S.specialBtn} ${
              adherenceNotification ? S.introAlert : ""
            }`}
            onClick={this.changeTab}
          >
            <Icon name="notification" className={S.notificationIcon} />
          </button>
        )}
      </div>
    )
  }

  renderBanner = ({ bannerText, desktopImage }, i) => (
    <div className={S.contentEntry} key={i}>
      <div className={S.bannerText}>{bannerText}</div>
      {desktopImage && <img src={desktopImage} className={S.img} />}
    </div>
  )

  renderSlider = ({ x }) => (
    <div style={{ left: `${x}%` }} className={S.slider}>
      {this.state.banners.map(this.renderBanner)}
      {this.props.introFlowActive && (
        <IntroFlow className={S.contentEntry} {...this.props} />
      )}
    </div>
  )

  render() {
    return (
      <div className={S.carousel}>
        <div
          className={S.content}
          style={{
            height: this.state.tab === END ? ADHERENCE_HEIGHT : BANNER_HEIGHT,
          }}
        >
          <Motion
            style={{
              x: spring(-contentPosition(this.state.tab), springConfig),
            }}
          >
            {this.renderSlider}
          </Motion>
        </div>
        {this.renderControls()}
      </div>
    )
  }
}

export default DashboardCarousel
