let passiveSupport = null

// adds { passive: true } to event listener options if supported
export function getListenerOptions(options) {
  if (passiveSupport !== null) return { ...options, ...passiveSupport }

  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get: function() {
          passiveSupport = { passive: true }
        },
      })
    )
  } catch (e) {
    passiveSupport = {}
  }
  return { ...options, ...passiveSupport }
}

export const doc =
  document.documentElement || document.body.parentNode || document.body
