import React, { Fragment, PureComponent } from "react"

import { withApi } from "component-library"
import { withAppState } from "components/providers/AppStateComponent"
import PrivateDesktopHeader from "./PrivateDesktopHeader"
import PrivateMobileHeader from "./PrivateMobileHeader"

const MIN_DELTA = 5
const didScroll = { didScroll: true }
const clearScroll = { didScroll: false }
function scrollTop() {
  return (document.scrollingElement || document.documentElement).scrollTop
}

import mall from "images/cart_shaded.png"
import S from "./Header.css"
export class PrivateHeader extends PureComponent {
  state = {
    lastScrollTop: 0,
    didScroll: false,
    hidden: false,
    override: false,
    height: 0,
  }

  headerRef = null

  lifecycle = null

  componentDidMount() {
    this.setState({ lastScrollTop: scrollTop() }, () => {
      window.addEventListener("scroll", this.recordScroll)
      this.lifecycle = setInterval(this.getHeaderStateFromScroll, 250)
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.recordScroll)
    window.clearInterval(this.lifecycle)
  }

  setHeaderRef = (el) => {
    this.headerRef = el
    el && this.setState({ height: el.scrollHeight })
  }

  updateHeaderHeight = () => {
    this.setState({ height: this.headerRef.scrollHeight })
  }

  recordScroll = () => this.setState(didScroll)
  getHeaderStateFromScroll = () => {
    const prevState = this.state

    if (prevState.didScroll) {
      const newPosition = this.shouldHeaderPositionUpdate()
      if (newPosition) {
        return this.setState({ didScroll: false, ...newPosition })
      }
    }
    return this.setState(clearScroll)
  }

  shouldHeaderPositionUpdate() {
    if (!this.headerRef) return false

    const current = scrollTop()
    const last = this.state.lastScrollTop
    if (Math.abs(last - current) <= MIN_DELTA) return false

    const height = this.state.height
    const goingDown = current > last && current > height
    return {
      lastScrollTop: current,
      hidden: goingDown,
    }
  }

  // when mobile menu is open, override hiding
  setOverride = (override) => this.setState({ override })

  headerClass = () =>
    `${S.header} ${
      this.state.hidden && !this.state.override ? S.headerUp : S.relax
    }`

  render() {
    if (this.props.error) {
      return "Something went wrong"
    } else if (this.props.loading) {
      return "Loading..."
    }

    return (
      <Fragment>
        <header ref={this.setHeaderRef} className={this.headerClass()}>
          <PrivateDesktopHeader {...this.props} modalImg={mall} />
          <PrivateMobileHeader
            modalImg={mall}
            setOverride={this.setOverride}
            updateHeaderHeight={this.updateHeaderHeight}
            dropdownTop={this.state.height}
            {...this.props}
          />
        </header>
        <div style={{ height: this.state.height }} />
      </Fragment>
    )
  }
}

export default withApi(withAppState(PrivateHeader), [
  {
    name: "getUser",
    path: "users/current",
    transform: (user) => ({ user }),
  },
  {
    name: "getPoints",
    path: "points/status",
    transform: (points) => ({ points }),
  },
  {
    name: "getTipAvailable",
    path: "articles/current/available",
    transform: ({ articleAvailable }) => ({ articleAvailable }),
  },
  {
    name: "getSurveyAvailable",
    path: "surveys/current/available",
    transform: ({ surveyQuizAvailable: surveyAvailable }) => ({
      surveyAvailable,
    }),
  },
  {
    name: "getQuizAvailable",
    path: "quizzes/current/available",
    transform: ({ surveyQuizAvailable: quizAvailable }) => ({ quizAvailable }),
  },
])
