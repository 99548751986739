import React, { PureComponent } from "react"

import beyaz from "images/logo_beyaz.png"
import natazia from "images/logo_natazia.png"
import safyral from "images/logo_safyral.png"
import yasmin from "images/logo_yasmin.png"
import yaz from "images/logo_yaz.png"
import S from "./SegmentLogos.css"
export class SegmentLogos extends PureComponent {
  render() {
    return (
      <section className={S.segments}>
        <div className={S.imgContainer}>
          <img className={S.beyaz} src={beyaz} />
        </div>
        <div className={S.imgContainer}>
          <img className={S.natazia} src={natazia} />
        </div>
        <div className={S.imgContainer}>
          <img className={S.safyral} src={safyral} />
        </div>
        <div className={S.imgContainer}>
          <img className={S.yasmin} src={yasmin} />
        </div>
        <div className={S.imgContainer}>
          <img className={S.yaz} src={yaz} />
        </div>
      </section>
    )
  }
}

export default SegmentLogos
