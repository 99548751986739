import React, { PureComponent } from "react"

import ScrollToFooter from "components/Footer/ScrollToFooter"

import S from "./PublicHeader.css"
import bayerLogo from "images/logo_bayer.png"
export class PublicHeader extends PureComponent {
  renderScroller({ text }) {
    return (
      <ScrollToFooter
        render={(scrollToFooter) => (
          <button className={S.Isi} onClick={scrollToFooter}>
            {text}
          </button>
        )}
      />
    )
  }

  render() {
    const { content: C } = this.props
    return (
      <header className={S.header}>
        <div className={S.center}>
          <div className={S.topLeft}>
            <C.isiHeading />
            <p>
              <C.isiTitle />
              <br />
              <C.isiBody />
            </p>
            <p>
              <C.additionalInfo scrollToIsi={this.renderScroller} />
            </p>
            <p>
              <C.additionalInfoNatazia scrollToIsi={this.renderScroller} />
            </p>
            <p>
              <C.prescribingInfo />
            </p>
          </div>
          <img className={S.img} src={bayerLogo} />
        </div>
      </header>
    )
  }
}

export default PublicHeader
