import { PureComponent } from "react"

export default class ErrorBoundary extends PureComponent {
  state = {
    hasError: false,
    pathname: "",
  }

  static getDerivedStateFromProps({ location }, { pathname }) {
    if (!location) return null

    if (!pathname) {
      return { pathname: location.pathname }
    } else if (location.pathname !== pathname) {
      return {
        hasError: false,
        pathname: location.pathname,
      }
    }

    return null
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    return this.props[this.state.hasError ? "errorMsg" : "children"]
  }
}
