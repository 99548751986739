import React, { Fragment, PureComponent } from "react"
import { withStreakState } from "components/providers/StreakStateComponent"

import { Scorecard, WeeklyDrawing, WelcomeModal } from "component-library"
import DashboardCarouselData from "./DashboardCarouselData"
import DashboardCarousel from "./DashboardCarousel"
import MobileDashContent from "./MobileDashContent"

const Scorecard_ = withStreakState(Scorecard)

import welcomeIcon from "./thumbs_up_shaded.png"
import S from "./Dashboard.css"
export class Dashboard extends PureComponent {
  state = {
    showModal: null,
  }

  static getDerivedStateFromProps(
    {
      location: { state },
    },
    { showModal }
  ) {
    return state && state.virginLogin && showModal === null
      ? { showModal: state.virginLogin }
      : null
  }

  closeModal = () => this.setState({ showModal: false })

  render() {
    const { content: C } = this.props
    return (
      <div className={S.comp}>
        {window.matchMedia("(max-width: 1023px)").matches ? (
          <MobileDashContent content={C} />
        ) : (
          <Fragment>
            <DashboardCarouselData
              component={DashboardCarousel}
              content={C.IntroFlow}
            />
            <Scorecard_ content={C} />
            <WeeklyDrawing content={C.WeeklyDrawing} />
          </Fragment>
        )}
        {this.state.showModal && (
          <WelcomeModal
            content={C.WelcomeModal}
            icon={welcomeIcon}
            points={this.props.location.state.points}
            onClose={this.closeModal}
          />
        )}
      </div>
    )
  }
}

export default Dashboard
