import React, { Fragment, PureComponent } from "react"
import { stringUtils } from "component-library"

import { Icon } from "icon-library"

import S from "./RewardsMallConfirmation.css"
export class RewardsMallConfirmation extends PureComponent {
  renderName = () => <span className={S.name}>{this.props.data.title}</span>

  renderPoints = () => (
    <Fragment>
      <span className={S.points}>
        {this.props.data.points.toLocaleString()}
      </span>{" "}
      point
      {stringUtils.plural(this.props.data.points)}
    </Fragment>
  )

  render() {
    const { content: C } = this.props
    const { imageUrl } = this.props.data
    return (
      <div className={S.comp}>
        <h2 className={S.header}>
          <span className={S.circle}>
            <Icon className={S.mallIcon} name="mall_open_wheels" />
          </span>
          <C.heading />
        </h2>
        <div className={S.description}>
          <div className={S.text}>
            <div className={S.pointsText}>
              <C.deduction renderPointValue={this.renderPoints} />
            </div>
            <C.msg renderName={this.renderName} />
          </div>
          <img className={S.img} src={imageUrl} />
        </div>
        <div className={S.buttons}>
          <button onClick={this.props.cancel}>
            <C.cancelBtn />
          </button>
          <button className={S.confirm} onClick={this.props.order}>
            <C.confirmBtn />
          </button>
        </div>
        {this.props.error && <div>Something went wrong...</div>}
      </div>
    )
  }
}

export default RewardsMallConfirmation
