import React, { PureComponent } from "react"
import moment from "moment"

import { Link, Redirect } from "react-router-dom"
import { stringUtils, businessUtils } from "component-library"
import { Icon } from "icon-library"

const userState = (x, key) =>
  x && x.userPointsResult.pointDispositionMessage.i18nMessageKey === key

import S from "./IntroFlow.css"
export class IntroFlow extends PureComponent {
  static defaultProps = {
    className: "",
  }

  state = {
    redirect: false,
  }

  verifyOptOut = () => {
    this.props.setContext({ verificationOptOut: true })
  }

  rewardsOptOut = () => {
    this.props.setContext({ mallOptOut: true })
  }

  redirect = ({ target }) => this.setState({ redirect: target.name })

  renderTimeOfDay() {
    const hour = moment().hour()

    if (hour < 12) {
      return "morning"
    } else if (hour < 18) {
      return "afternoon"
    } else {
      return "evening"
    }
  }

  renderStreak = (streakStatus) => () => (
    <span className={S.bold}>{streakStatus.currentStreak} day</span>
  )

  renderPoints = (a, b) => {
    const sum = [a, b].reduce(
      (acc, report) => (report ? acc + report.userPointsResult.points : acc),
      0
    )

    return (
      <span className={S.bold}>
        {sum.toLocaleString()} point
        {stringUtils.plural(sum)}
      </span>
    )
  }

  renderAlert = ({
    content: C,
    checkInToday,
    checkInYesterday,
    optOutToday,
    optOutReceived,
    points,
    primaryRx,
    primaryStatus,
    quizAvailable,
    surveyAvailable,
    receivePoints,
    receivedPoints,
    reportedToday,
    reportedYesterday,
    streakStatus,
    verificationOptOut,
  }) => {
    const maxPoints =
      userState(reportedToday, "MSG_USER_AT_MAX_POINTS") ||
      userState(reportedYesterday, "MSG_USER_AT_MAX_POINTS")

    if (!primaryStatus.verified && !verificationOptOut) {
      return (
        <div className={S.alert}>
          <Icon name="nav_rx" className={S.rxIcon} />
          <div className={S.alertContent}>
            <div className={S.alertTitle}>
              <C.unverifiedHeading />
            </div>
            <div className={S.alertText}>
              <C.unverifiedMsg
                renderMedName={businessUtils.renderMedName(primaryRx)}
              />
            </div>
            <div className={S.btns}>
              <button className={S.btnLink}>
                <Link to="/hp/prescription">
                  <C.verifyBtn />
                </Link>
              </button>
              <button name="Today" onClick={this.verifyOptOut}>
                <C.unverifiedCloseBtn />
              </button>
            </div>
          </div>
        </div>
      )
    } else if (checkInToday && !reportedToday && !optOutToday) {
      return (
        <div className={S.alert}>
          <Icon name="enclosed_rx" className={S.rxIcon} />
          <div className={S.alertContent}>
            <div className={S.alertTitle}>
              <C.todayHeading renderTimeOfDay={this.renderTimeOfDay} />
            </div>
            <div>
              <C.todayMsg />
            </div>
            <div className={S.btns}>
              <button onClick={this.props.reportToday}>
                <C.todayReportBtn />
              </button>
              <button name="Today" onClick={this.props.optOut}>
                <C.todayCloseBtn />
              </button>
            </div>
          </div>
        </div>
      )
    } else if (optOutToday && !optOutReceived) {
      return (
        <div className={S.alert}>
          <Icon name="enclosed_check" className={S.rxIcon} />
          <div className={S.alertContent}>
            <div className={S.alertTitle}>
              <C.optOutTodayHeading />
            </div>
            <div>
              <C.optOutTodayMsg />
            </div>
            <div className={S.btns}>
              <button name="off" onClick={this.props.optOut}>
                <C.optOutCloseBtn />
              </button>
            </div>
          </div>
        </div>
      )
    } else if (checkInYesterday && !reportedYesterday) {
      return (
        <div className={S.alert}>
          <Icon name="enclosed_rx" className={S.rxIcon} />
          <div className={S.alertContent}>
            {reportedToday && (
              <div className={S.thanks}>
                <C.ydayThanksTodayMsg />
              </div>
            )}
            <div>
              <C.ydayMsg />
            </div>
            <div className={S.btns}>
              <button onClick={this.props.reportYesterday}>
                <C.ydayReportBtn />
              </button>
              <button name="Yesterday" onClick={this.props.optOut}>
                <C.ydayCloseBtn />
              </button>
            </div>
          </div>
        </div>
      )
    } else if ((reportedToday || reportedYesterday) && !receivedPoints) {
      if (
        userState(reportedToday, "MSG_USER_INELIGIBLE_TO_EARN") ||
        userState(reportedYesterday, "MSG_USER_INELIGIBLE_TO_EARN")
      ) {
        return (
          <div className={S.alert}>
            <Icon name="enclosed_check" className={S.rxIcon} />
            <div className={S.alertContent}>
              <div className={S.alertTitle}>
                <C.reportUnverifiedHeading />
              </div>
              <div className={S.alertText}>
                <C.reportUnverifiedMsg
                  renderPoints={this.renderPoints(
                    reportedToday,
                    reportedYesterday
                  )}
                />
              </div>
              <div className={S.btns}>
                <button className={S.btnLink}>
                  <Link to="/hp/prescription">
                    <C.reportUnverifiedVerifyBtn />
                  </Link>
                </button>
                <button name="Today" onClick={receivePoints}>
                  <C.reportUnverifiedCloseBtn />
                </button>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={S.pointsAlert}>
            <div className={S.pointsPanels}>
              <div className={S.pointsPanel}>
                <Icon
                  name={maxPoints ? "max" : "streak_trophy"}
                  className={S.pointsIcon}
                />
                <span>
                  {maxPoints ? (
                    <C.reportMaxMsg />
                  ) : (
                    <C.reportMsg
                      renderPoints={this.renderPoints(
                        reportedToday,
                        reportedYesterday
                      )}
                    />
                  )}
                </span>
              </div>
              <div className={S.pointsPanel}>
                <Icon name="streak_shield" className={S.pointsIcon} />
                <span>
                  <C.reportStreakMsg
                    renderStreak={this.renderStreak(streakStatus)}
                  />
                </span>
              </div>
            </div>
            <div className={S.btns}>
              <button onClick={receivePoints}>
                <C.reportCloseBtn />
              </button>
            </div>
          </div>
        )
      }
    } else if (quizAvailable || surveyAvailable) {
      return (
        <div className={S.contentAlert}>
          <Icon name="notification" className={S.notificationIcon} />
          <div className={S.alertContent}>
            <div className={S.alertTitle}>
              <C.qsHeading />
            </div>
            <div>
              <C.qsMsg />
            </div>
            <div className={S.btns}>
              <button
                className={S.contentBtn}
                name="/hp/quiz"
                onClick={this.redirect}
                disabled={!quizAvailable}
              >
                <C.quizBtn />
              </button>
              <button
                className={S.contentBtn}
                name="/hp/survey"
                onClick={this.redirect}
                disabled={!surveyAvailable}
              >
                <C.surveyBtn />
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={S.contentAlert}>
          <Icon name="max" className={S.notificationIcon} />
          <div className={S.alertContent}>
            <div className={S.alertTitle}>
              <C.maxPointsHeading />
            </div>
            <div>
              <C.maxPointsMsg1 />
              <br />
              <C.maxPointsMsg2
                renderResumeDate={moment(points.maxResetOn).format(
                  "MMMM D, YYYY"
                )}
              />
            </div>
            <div className={S.btns}>
              <button className={S.btnLink}>
                <Link to="/hp/rewards-mall">
                  <C.mallBtn />
                </Link>
              </button>
              <button className={S.contentBtn} onClick={this.rewardsOptOut}>
                <C.maxCloseBtn />
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />

    return (
      <div className={this.props.className}>{this.renderAlert(this.props)}</div>
    )
  }
}

export default IntroFlow
