import { server } from "component-library"

export function setDefaults() {
  server.defaults.baseURL = BAYER_API_URL
  server.defaults.headers.common["X-HP-PROGRAM-KEY"] = BAYER_PROGRAM_KEY
  server.defaults.headers.common["CLIENT_ID"] = BAYER_AUTH0_CLIENT_ID
  server.defaults.headers.common["Accept"] = "application/json"
  server.defaults.headers.post["Content-Type"] = "application/json"
}

export function setAuthHeaders() {
  server.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`
}
