import React, { PureComponent } from "react"

import { withContent } from "content-library"
import ScrollToFooter from "components/Footer/ScrollToFooter"

import S from "./HeaderText.css"
export class HeaderText extends PureComponent {
  renderScroller({ text }) {
    return (
      <ScrollToFooter
        render={(scrollToFooter) => (
          <button className={S.Isi} onClick={scrollToFooter}>
            {text}
          </button>
        )}
      />
    )
  }

  render() {
    const { content: C, primaryRx } = this.props
    const segmentName = primaryRx.medicationDto.name
    const PrescribingInfo =
      C[`prescribingInfo${segmentName}`] || C.prescribingInfoNatazia
    return (
      <div className={S.topLeft}>
        <C.isiHeading />
        <br />
        <p>
          <C.isiTitle />
          <br />
          <C.isiBody segmentName={() => segmentName} />
        </p>
        <div className={S.isiSection}>
          <C.additionalInfo scrollToIsi={this.renderScroller} />
        </div>
        <div className={S.rxInfoSection}>
          <PrescribingInfo />
        </div>
      </div>
    )
  }
}

export default withContent(HeaderText, "HeaderText")
