import React, { Component } from "react"

import { withRouter } from "react-router-dom"
import { withContent } from "content-library"
import { withAppState } from "components/providers/AppStateComponent"
import { sidebar as sidebarContent } from "lib/routeContentKeys"

import {
  LeaderboardWidget,
  RewardsCarousel,
  RxVerificationWidget,
  UserPoints,
} from "component-library"

const Points = withAppState(UserPoints)
const Rewards = withAppState(RewardsCarousel)

const noSidebarRoute = (path) =>
  [
    "/hp/communication",
    "/hp/opt-out",
    "/hp/prescription",
    "/hp/privacy",
    "/hp/profile",
    "/hp/terms",
  ].indexOf(path) !== -1

import mall from "images/cart_shaded.png"
import S from "./Sidebar.css"
export class Sidebar extends Component {
  isMobile = window.matchMedia("(max-width: 1023px)").matches

  render() {
    const { content: C, location } = this.props
    const { pathname } = location
    if (noSidebarRoute(pathname) || this.isMobile) return null

    return (
      <div className={S.sidebar}>
        <Points content={C.UserPoints} />
        <RxVerificationWidget content={C.RxVerificationWidget} />
        <LeaderboardWidget content={C.LeaderboardWidget} />
        {pathname !== "/hp/rewards-mall" && (
          <Rewards modalImg={mall} content={C.RewardsCarousel} />
        )}
      </div>
    )
  }
}

export default withRouter(withContent(Sidebar, sidebarContent))
