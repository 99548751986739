import React, { PureComponent } from "react"
import { bayerContent, setExport } from "content-library"

import LoadingModal from "components/LoadingModal/LoadingModal"

export class ContentLoader extends PureComponent {
  state = {
    contentReady: false,
  }

  componentDidMount() {
    // simulate api call
    window.setTimeout(() => {
      setExport(bayerContent)
      this.setState({ contentReady: true })
    }, 0)
  }

  render() {
    return this.state.contentReady ? this.props.children : <LoadingModal />
  }
}

export default ContentLoader
