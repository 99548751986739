import React, { Component } from "react"

import S from "./NoMatch.css"
export class NoMatch extends Component {
  goBack = () => this.props.history.goBack()

  render() {
    return (
      <div className={S.comp}>
        <h2>Uh oh!</h2>
        It would seem something is broken!
        <br />
        (404 page not found)
        <button onClick={this.goBack}>Go Back</button>
      </div>
    )
  }
}

export default NoMatch
