const sidebar = [
  "LeaderboardWidget",
  "RewardsCarousel",
  "RxVerificationWidget",
  "UserPoints",
]
const dashboard = [
  "IntroFlow",
  "LeaderboardWidget",
  "RewardsCarousel",
  "RxVerificationWidget",
  "Scorecard",
  "ScorecardWeek",
  "ScorecardUserSummary",
  "WeeklyDrawing",
  "WelcomeModal",
]
const prescription = ["RxVerification", "GetACard", "CoPay", "Upload"]
const profile = "UserProfile"
const communication = "Notifications"
const survey = [
  "QuizOrSurveyHistory",
  "QuizOrSurvey",
  "QuestionProgress",
  "SurveyQuestion",
  "SurveyResults",
]
const quiz = [
  "QuestionProgress",
  "QuizOrSurveyHistory",
  "QuizOrSurvey",
  "QuizQuestion",
  "QuizQuestionResult",
  "QuizResults",
]
const rewards = [
  "RewardsMall",
  "RewardsMallProduct",
  "RewardsMallHeader",
  "RewardsMallConfirmation",
  "RewardsMallPopUp",
]
const tip = ["DailyTip", "DailyTipHistory"]
const landing = [
  "ForgotPassword",
  "Landing",
  "LandingContent",
  "PrivacyPolicy",
  "PublicHeader",
  "ResetPassword",
  "Rules",
  "SignIn",
  "SignUp",
  "Support",
  "SupportLink",
  "TermsOfUse",
  "WelcomeBack",
]

export {
  communication,
  dashboard,
  landing,
  prescription,
  profile,
  quiz,
  rewards,
  sidebar,
  survey,
  tip,
}
