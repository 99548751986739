import React, { PureComponent } from "react"

import Beyaz from "images/logo_beyaz.png"
import Natazia from "images/logo_natazia.png"
import Safyral from "images/logo_safyral.png"
import Yasmin from "images/logo_yasmin.png"
import Yaz from "images/logo_yaz.png"

const LOGOS = {
  Beyaz,
  Natazia,
  Safyral,
  Yasmin,
  Yaz,
}

import S from "./Logo.css"
import pillPoints from "images/logo_pillpoints.png"
export class Logo extends PureComponent {
  static defaultProps = {
    className: "",
  }

  goHome = () => this.props.history.push(this.props.to)

  render() {
    const { primaryRx } = this.props

    let segmentLogo
    if (primaryRx) {
      const segmentMed = primaryRx.medicationDto.name
      segmentLogo = LOGOS[segmentMed]
    }

    return (
      <button
        className={`${S.logo} ${this.props.className}`}
        onClick={this.goHome}
      >
        <img className={S.pillPoints} src={pillPoints} />
        {segmentLogo && <img src={segmentLogo} className={S.segmentLogo} />}
      </button>
    )
  }
}

export default Logo
