import React, { PureComponent } from "react"
import moment from "moment"
import { formatRxData } from "component-library"

import { withApi, withDrawingsPolyfill } from "component-library"

const StreakState = React.createContext({
  getRxStreakLoading: false,
  getDrawingsLoading: false,
})

export class StreakStateComponent extends PureComponent {
  state = {
    contextLoading: true,
    getRxStreak: this.props.getRxStreak,
    getDrawings: this.props.getDrawings,
    rxStreak: null,
    drawings: null,
  }

  static getDerivedStateFromProps(
    {
      getDrawingsLoading,
      getDrawingsSuccess,
      drawings,
      getRxStreakLoading,
      getRxStreakSuccess,
      rxStreak,
    },
    { contextLoading }
  ) {
    if (getRxStreakSuccess && getDrawingsSuccess && contextLoading) {
      return { rxStreak, drawings, contextLoading: false }
    } else if ((getRxStreakLoading || getDrawingsLoading) && !contextLoading) {
      return { contextLoading: true }
    } else {
      return null
    }
  }

  render() {
    return (
      <StreakState.Provider value={this.state}>
        {this.state.rxStreak && this.state.drawings && this.props.children}
      </StreakState.Provider>
    )
  }
}

export function withStreakState(Component) {
  return function StatefulComponent(props) {
    return (
      <StreakState.Consumer>
        {(contextProps) => <Component {...props} {...contextProps} />}
      </StreakState.Consumer>
    )
  }
}

const formatDate = (m = now.clone()) => m.format("YYYY-MM-DD")

const now = moment().endOf("day")
const drawingStart = formatDate(now.clone().subtract(3, "weeks"))
const streakStart = formatDate(now.clone().subtract(13, "days"))

export default withApi(withDrawingsPolyfill(StreakStateComponent), [
  {
    name: "getRxStreak",
    path: "statistics/scorecard-days",
    params: {
      params: {
        start: streakStart,
        end: formatDate(),
      },
    },
    bustCache: true,
    transform: (data) => ({ rxStreak: formatRxData(data) }),
  },
  {
    name: "getDrawings",
    path: "weekly-drawings",
    params: { params: { limit: 3, start: drawingStart } },
    bustCache: true,
  },
])
