import React, { PureComponent } from "react"
import Auth from "lib/auth"

import { withContent } from "content-library"
import {
  Modal,
  PrivacyPolicy,
  ResetPassword,
  Rules,
  SignIn,
  Support,
  TermsOfUse,
  WelcomeBack,
} from "component-library"

import { Link, Route, Switch } from "react-router-dom"
import PublicHeader from "components/Header/PublicHeader"
import Footer from "components/Footer/Footer"
import NoMatch from "components/NoMatch/NoMatch"
import SignUp from "components/SignUp/SignUp"
import LoadingModal from "components/LoadingModal/LoadingModal"
import LandingContent from "./LandingContent"
import Logo from "../Header/Logo"

const PublicFooter = withContent(Footer, ["PrivateFooter", "PublicFooter"])

import notification from "images/exclamation_shaded.png"
import check from "images/check_shaded.png"
import pencil from "images/pencil_shaded.png"
import S from "./Landing.css"
export class Landing extends PureComponent {
  state = {
    redirect: false,
    hasError: false,
    showModal: false,
  }

  static getDerivedStateFromProps({ location: { state } }) {
    return state && state.autoLogged ? { showModal: "welcome" } : null
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  componentDidMount() {
    const result = Auth.checkForAuth0Data((path) =>
      this.props.history.push(path)
    )

    if (result) {
      this.setState({ redirect: true })
    }
  }

  showModal = () => this.setState({ showModal: "signIn" })
  closeModal = () => this.setState({ showModal: false })

  fromLink() {
    const { state } = this.props.location
    return (state && state.pathname) || "/hp/dashboard"
  }

  redirectSignIn = () => {
    Auth.authorize(this.fromLink())
  }

  renderModal() {
    const { showModal } = this.state
    const { content, location } = this.props
    if (showModal === "signIn") {
      return (
        <SignIn
          content={content}
          location={location}
          successImg={check}
          forgotImg={pencil}
          signInImg={pencil}
          redirectSignIn={this.redirectSignIn}
          className={S.signInModal}
          onClose={this.closeModal}
        />
      )
    } else if (showModal === "welcome") {
      return (
        <WelcomeBack
          content={content.WelcomeBack}
          img={notification}
          onClose={this.showModal}
        />
      )
    } else {
      return (
        <ResetPassword
          content={content.ResetPassword}
          img={pencil}
          onClose={this.closeModal}
          className={S.resetModal}
        />
      )
    }
  }

  render() {
    if (this.state.hasError) {
      return "Something went wrong."
    } else if (this.state.redirect) {
      return <LoadingModal />
    }
    const { content: C } = this.props
    return (
      <div className={S.landing}>
        {this.state.showModal && (
          <Modal closeButton onClose={this.closeModal}>
            {this.renderModal()}
          </Modal>
        )}

        <PublicHeader content={C.PublicHeader} />
        <div className={S.mainline}>
          <div className={S.main}>
            <Logo className={S.logo} to="/" history={this.props.history} />
            {this.props.location.pathname !== "/register" && (
              <button className={S.signUp}>
                <Link to="/register">
                  <C.Landing.signUpBtn />
                </Link>
              </button>
            )}
            <button className={S.signIn} onClick={this.showModal}>
              <C.Landing.signInBtn />
            </button>
          </div>
        </div>
        <Route
          exact
          path="/"
          render={(props) => (
            <LandingContent {...props} content={C.LandingContent} />
          )}
        />
        <Route
          path="/register"
          render={(props) => (
            <SignUp {...props} content={C.SignUp} Auth={Auth} />
          )}
        />
        <div className={S.staticContent}>
          <Switch>
            <Route exact path="/" render={() => null} />
            <Route path="/register" render={() => null} />
            <Route path="/rules" render={() => <Rules content={C.Rules} />} />
            <Route
              path="/support"
              render={() => <Support public content={C} />}
            />
            <Route
              path="/terms"
              render={() => <TermsOfUse content={C.TermsOfUse} />}
            />
            <Route
              path="/privacy"
              render={() => <PrivacyPolicy content={C.PrivacyPolicy} />}
            />
            <Route component={NoMatch} />
          </Switch>
        </div>
        <PublicFooter landing />
      </div>
    )
  }
}

export default Landing
