import auth0 from "auth0-js"

export default new auth0.WebAuth({
  domain: BAYER_AUTH0_DOMAIN,
  clientID: BAYER_AUTH0_CLIENT_ID,
  redirectUri: BAYER_AUTH0_CALLBACK_URI,
  audience: BAYER_API_URL,
  responseType: "token id_token",
  scope: "openid, email profile",
})
