import React, { Fragment, PureComponent } from "react"

import { Link } from "react-router-dom"
import { Icon } from "icon-library"
import { Modal } from "component-library"

const plural = (p) => (p === 1 ? "" : "s")

import S from "./RewardsMallPopUp.css"
export class RewardsMallPopUp extends PureComponent {
  renderCancel = (C) => (
    <Fragment>
      <h2 className={S.h2}>
        <Icon className={S.icon} name="notification" />
        <C.cancelHeading />
      </h2>
      <div className={S.body}>
        <p>
          <C.cancelMsg />
        </p>
        <button className={S.closePopup} onClick={this.props.close}>
          <C.cancelBtn />
        </button>
      </div>
    </Fragment>
  )

  renderNotEnoughPoints = (C) => (
    <Fragment>
      <h2 className={S.h2}>
        <Icon className={S.icon} name="notification" />
        <C.noPointsHeading />
      </h2>
      <div className={S.body}>
        <p>
          <C.noPointsMsg />
        </p>
        <button className={S.closePopup} onClick={this.props.close}>
          <C.noPointsBtn />
        </button>
      </div>
    </Fragment>
  )

  renderPoints = () => (
    <span className={S.points}>
      {this.props.product.points.toLocaleString()} point
      {plural(this.props.product.points)}
    </span>
  )

  renderSuccess = (C) => (
    <Fragment>
      <h2 className={S.h2}>
        <span className={S.circle}>
          <Icon className={S.icon} name="check" />
        </span>
        <C.successHeading />
      </h2>
      <div className={S.body}>
        <p>
          <C.successMsg1 renderPoints={this.renderPoints} />
        </p>
        <p>
          <C.successMsg />
        </p>
        <span className={S.closePopup}>
          <button className={S.dashboardLink}>
            <Link to="/hp/dashboard">
              <C.successDashBtn />
            </Link>
          </button>
          <button onClick={this.props.close}>
            <C.successCloseBtn />
          </button>
        </span>
      </div>
    </Fragment>
  )

  render() {
    const { content: C } = this.props
    return (
      <Modal blur={false} className={S.modal}>
        {this[`render${this.props.view}`](C)}
      </Modal>
    )
  }
}

export default RewardsMallPopUp
