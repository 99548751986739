import React, { PureComponent } from "react"

import S from "./RewardsMallHeader.css"
export class RewardsMallHeader extends PureComponent {
  render() {
    const { content: C } = this.props
    return (
      <header className={S.header}>
        <h2>
          <C.heading />
        </h2>
        <p className={S.welcome}>
          <C.msg />
        </p>
      </header>
    )
  }
}

export default RewardsMallHeader
