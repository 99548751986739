import React, { Component } from "react"

import { Link } from "react-router-dom"
import LoadingModal from "components/LoadingModal/LoadingModal"

import clipboard from "images/clipboard_shaded.png"
import S from "./SignUpStepTwo.css"
export class SignUpStepTwo extends Component {
  linkTerms = ({ text }) => (
    <Link target="_blank" to="/terms" className={S.agreements}>
      {text}
    </Link>
  )

  linkPriv = ({ text }) => (
    <Link target="_blank" to="/" className={S.agreements}>
      {text}
    </Link>
  )

  renderForm(C) {
    const {
      agreeToTerms,
      onChange,
      onFederalInsurancePlan,
      onSubmit,
      residentOfStateWithStateInsurancePlan,
    } = this.props

    const disabled = !(
      agreeToTerms &&
      !onFederalInsurancePlan &&
      !residentOfStateWithStateInsurancePlan
    )
    return (
      <form className={S.form} onSubmit={onSubmit}>
        <label className={S.label}>
          <input
            className={S.input}
            name="residentOfStateWithStateInsurancePlan"
            checked={!residentOfStateWithStateInsurancePlan}
            onChange={onChange}
            type="checkbox"
          />
          <C.terms1 />
        </label>
        <label className={S.label}>
          <input
            className={S.input}
            name="onFederalInsurancePlan"
            checked={!onFederalInsurancePlan}
            onChange={onChange}
            type="checkbox"
          />
          <C.terms2 />
        </label>
        <label className={S.label}>
          <input
            className={S.input}
            name="agreeToTerms"
            checked={agreeToTerms}
            onChange={onChange}
            type="checkbox"
          />
          <C.terms3 newTabTerms={this.linkTerms} newTabPriv={this.linkPriv} />
        </label>
        <button className={`${S.button} ${S.submit}`} disabled={disabled}>
          <C.step2Btn />
        </button>
      </form>
    )
  }

  render() {
    const { content: C } = this.props
    return (
      <div className={S.signUpTwo}>
        <img src={clipboard} className={S.headerIcon} />
        <div className={S.title}>
          <C.step2Heading />
        </div>
        <div className={S.subtitle}>
          <C.step2Heading2 />
        </div>
        {this.renderForm(C)}
        {this.props.loading && <LoadingModal />}
        {this.props.error && <div>Something went wrong.</div>}
      </div>
    )
  }
}

export default SignUpStepTwo
