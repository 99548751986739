import moment from "moment"
import auth0 from "lib/auth0"
import { setAuthHeaders } from "lib/axios"

const Auth = {
  isValid() {
    return +moment() < localStorage.getItem("expires_at")
  },

  authorize(redirect) {
    localStorage.setItem("redirect", redirect)
    auth0.authorize({ connection: BAYER_AUTH0_CONNECTION })
  },

  // if auth and hitting login, redirect to dashboard
  // if auth data in path, follow redirect in storage
  // otherwise return false (login renders)
  checkForAuth0Data(redirect) {
    if (this.isValid()) {
      redirect("/hp/dashboard")
      return true
    }

    const tokenInHash = /access_token|error/.test(window.location.hash)
    return tokenInHash && this.handleAuthentication(redirect)
  },

  handleAuthentication(redirect) {
    auth0.parseHash((err, authResult) => {
      this.removeCallbackHashParams()

      if (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        throw `Error: ${err.error}. Check the console for further details.`
      }

      this.setSession(authResult)
      redirect(localStorage.getItem("redirect"))
    })
    return true
  },

  setSession(authResult) {
    const aDayFromNow = authResult.expiresIn * 1000 + moment()

    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", JSON.stringify(aDayFromNow))

    setAuthHeaders()
  },

  removeCallbackHashParams() {
    window.history.replaceState(
      "",
      "",
      `${window.location.pathname}${window.location.search}`
    )
  },

  clearSession() {
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("redirect")
    auth0.logout()
  },
}

export default Auth
