import React, { Fragment, PureComponent } from "react"

import { Link } from "react-router-dom"
import { Icon } from "icon-library"
import SegmentLogos from "components/SegmentLogos/SegmentLogos"

import devices from "./mbp_iphone.png"
import iphone from "./iphone.png"
import S from "./LandingContent.css"
export class LandingContent extends PureComponent {
  state = {
    showModal: false,
  }

  showModal = () => this.setState({ showModal: true })
  closeModal = () => this.setState({ showModal: false })

  renderTm = () => <span className={S.tm}>™</span>

  render() {
    const { content: C } = this.props
    return (
      <Fragment>
        <SegmentLogos />
        <section className={S.ctaContainer}>
          <div className={S.cta}>
            <div className={S.cta1}>
              <C.headline1 renderTm={this.renderTm} />
            </div>
            <div className={S.cta2}>
              <C.headline2 />
            </div>
            <div className={S.signUpContainer}>
              <button className={S.signUp}>
                <Link to="/register">Sign up now!</Link>
              </button>
            </div>
          </div>
        </section>
        <section className={S.featuresContainer}>
          <div className={S.features}>
            <div className={S.column}>
              <div>
                <C.featureHeadline />
              </div>
              <ul className={S.featureList}>
                <li>
                  <Icon name="check" className={S.checkIcon} />
                  <span className={S.featureLabel}>
                    <C.featureItem1 />
                  </span>
                </li>
                <li>
                  <Icon name="check" className={S.checkIcon} />
                  <span className={S.featureLabel}>
                    <C.featureItem2 />
                  </span>
                </li>
                <li>
                  <Icon name="check" className={S.checkIcon} />
                  <span className={S.featureLabel}>
                    <C.featureItem3 />
                  </span>
                </li>
                <li>
                  <Icon name="check" className={S.checkIcon} />
                  <span className={S.featureLabel}>
                    <C.featureItem4 />
                  </span>
                </li>
              </ul>
            </div>
            <img src={devices} className={S.devices} />
            <img src={iphone} className={S.iphone} />
          </div>
        </section>
      </Fragment>
    )
  }
}

export default LandingContent
