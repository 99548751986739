import React, { PureComponent } from "react"
import { doc } from "lib/domUtils"

import { Motion, spring } from "react-motion"

class ScrollSink extends PureComponent {
  componentDidUpdate(prevProps) {
    if (!this.props.isResting && prevProps.scrollTop !== this.props.scrollTop) {
      doc.scrollTop = this.props.scrollTop
    }
  }

  render() {
    return this.props.render(this.props.scrollToFooter)
  }
}

const isResting = { isResting: true }
const springConfig = { precision: 1 }

export class ScrollToFooter extends PureComponent {
  state = {
    scrollTop: 0,
    isResting: true,
  }

  scrollToFooter = () => {
    this.setState({ scrollTop: window.scrollY }, () => {
      const height = document.getElementById("footer")
      this.setState({
        isResting: false,
        scrollTop: window.pageYOffset + height.getBoundingClientRect().top,
      })
    })
  }

  renderScroller = (currentStyles) => (
    <ScrollSink
      render={this.props.render}
      scrollToFooter={this.scrollToFooter}
      scrollTop={currentStyles.scrollTop}
      isResting={this.state.isResting}
    />
  )

  onRest = () => this.setState(isResting)

  render() {
    return (
      <Motion
        style={{
          scrollTop: this.state.isResting
            ? this.state.scrollTop
            : spring(this.state.scrollTop, springConfig),
        }}
        onRest={this.onRest}
      >
        {this.renderScroller}
      </Motion>
    )
  }
}

export default ScrollToFooter
