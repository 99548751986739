import React from "react"
import { NavLink } from "react-router-dom"
import { stringUtils } from "component-library"

import { Icon } from "icon-library"

export const ICONS = {
  "/": "signout",
  "/hp/communication": "cog",
  "/hp/daily-tip": "daily_tip",
  "/hp/dashboard": "home",
  "/hp/medicine-cabinet": "rx",
  "/hp/monthly-points": "points",
  "/hp/prescription": "rx",
  "/hp/profile": "cog",
  "/hp/quiz": "quiz",
  "/hp/rewards-mall": "mall",
  "/hp/rules": "rules",
  "/hp/support": "support",
  "/hp/survey": "survey",
}

const { reverseKebabCase, capitalize } = stringUtils

function addOnClick(to, logout, gotoMall, onClick) {
  if (to === "/") {
    return logout
  } else if (to === "/hp/rewards-mall") {
    return gotoMall
  } else {
    return onClick
  }
}

function prettyPrint(s) {
  if (s === "/") return "Log Out"
  return capitalize(reverseKebabCase(s.slice(4)))
}

import S from "./MobileIconLink.css"
export default function MobileIconLink({
  articleAvailable,
  gotoMall,
  location,
  logout,
  onClick,
  quizAvailable,
  surveyAvailable,
  to,
}) {
  const activePath = location.pathname

  const tipMark =
    to === "/hp/daily-tip" && activePath !== "/hp/daily-tip" && articleAvailable

  const quizMark =
    to === "/hp/quiz" && activePath !== "/hp/quiz" && quizAvailable

  const surveyMark =
    to === "/hp/survey" && activePath !== "/hp/survey" && surveyAvailable

  const name = ICONS[to]
  const iconRef = name === "cog" ? "cog" : `nav_${name}`
  return (
    <NavLink
      className={S.link}
      exact={true}
      activeClassName={S.activeLink}
      to={to}
      onClick={addOnClick(to, logout, gotoMall, onClick)}
    >
      <Icon name={iconRef} className={S.icon} />
      {prettyPrint(to)}
      {(tipMark || quizMark || surveyMark) && (
        <Icon name="notification" className={S.noti} />
      )}
    </NavLink>
  )
}
