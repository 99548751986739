import React, { Component } from "react"

import { DobInput, ShowHidePasswordInput } from "component-library"

import natazia from "images/logo_natazia.png"
import beyaz from "images/logo_beyaz.png"
import yaz from "images/logo_yaz.png"
import yasmin from "images/logo_yasmin.png"
import safyral from "images/logo_safyral.png"
const SEGMENTS = [
  {
    name: "beyaz",
    id: "43",
    src: beyaz,
  },
  {
    name: "natazia",
    id: "44",
    src: natazia,
  },
  {
    name: "safyral",
    id: "45",
    src: safyral,
  },
  {
    name: "yasmin",
    id: "46",
    src: yasmin,
  },
  {
    name: "yaz",
    id: "42",
    src: yaz,
  },
]

import pencil from "images/pencil_shaded.png"
import S from "./SignUpStepOne.css"
export class SignUpStepOne extends Component {
  handleDobChange = (value) =>
    this.props.onChange({ target: { name: "dateOfBirth", value } })

  renderSegment = (s) => (
    <label
      key={s.id}
      className={`${S.segmentLabel} ${
        s.id === this.props.medicationId ? S.checked : ""
      }`}
    >
      <input
        type="radio"
        name="medicationId"
        value={s.id}
        onChange={this.props.onChange}
        checked={s.id === this.props.medicationId}
      />
      <img src={s.src} />
    </label>
  )

  renderForm(C) {
    const {
      onChange,
      validationErrors: { email: eError, password: pError, dob: dError },
      ...form
    } = this.props
    const disabled = !(
      form.firstName &&
      form.email &&
      !eError &&
      form.password &&
      !pError &&
      form.medicationId &&
      form.dateOfBirth &&
      !dError
    )
    return (
      <form className={S.form} onSubmit={this.props.onSubmit}>
        <div className={S.formLabel}>
          <C.formLabel1 />
        </div>
        <div className={S.segments}>{SEGMENTS.map(this.renderSegment)}</div>
        <div className={S.userFields}>
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={onChange}
            placeholder={C.firstNamePlaceholder()}
            autoFocus
          />
          <span className={S.emailContainer}>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={onChange}
              className={`${S.special} ${eError ? S.invalidInput : ""}`}
              placeholder={C.emailPlaceholder()}
            />
          </span>
          <div className={S.formLabel}>
            <C.formLabel2 />
          </div>
          <DobInput
            className={`${S.dob} ${dError ? S.invalidInput : ""}`}
            onChange={this.handleDobChange}
          />
          <ShowHidePasswordInput
            value={form.password}
            onChange={onChange}
            className={`${S.special} ${pError ? S.invalidInput : ""}`}
          />
          <button className={`${S.button} ${S.submit}`} disabled={disabled}>
            <C.step1Btn />
          </button>
        </div>
      </form>
    )
  }

  renderValidationError({ email, password, dob }, C) {
    return (
      <div className={S.invalidMsg}>
        {email && <C.emailError />}
        {dob && <C.dobError />}
        {password && (
          <div className={S.invalidPassword}>
            <C.passwordError />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { content: C } = this.props
    return (
      <div className={S.signUpOne}>
        <img src={pencil} className={S.headerIcon} />
        <div className={S.title}>
          <C.step1Heading />
        </div>
        <div className={S.subtitle}>
          <C.step1Heading2 />
        </div>
        {this.renderForm(C)}
        {this.renderValidationError(this.props.validationErrors, C)}
      </div>
    )
  }
}

export default SignUpStepOne
