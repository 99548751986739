import React, { PureComponent } from "react"
import moment from "moment"
import { server } from "component-library"

import { withStreakState } from "components/providers/StreakStateComponent"
import { withAppState } from "components/providers/AppStateComponent"

const CancelToken = server.CancelToken

const today = () => moment().format("YYYY-MM-DD")
const yesterday = () =>
  moment()
    .subtract(1, "days")
    .format("YYYY-MM-DD")

export class DashboardCarouselData extends PureComponent {
  state = {
    loading: true,
    error: false,
    reportedToday: false,
    reportedYesterday: false,
    receivedPoints: false,
    optOutToday: false,
    optOutReceived: false,

    checkInToday: null,
    checkInYesterday: null,
    bannerData: null,
  }

  tokenSource = CancelToken.source()

  componentDidMount() {
    const { id } = this.props.primaryRx
    server
      .all([
        server.get(`adherences/self-reports/prescriptions/${id}/allowed`, {
          params: { date: today() },
          cancelToken: this.tokenSource.token,
          bustCache: true,
        }),
        server.get(`adherences/self-reports/prescriptions/${id}/allowed`, {
          params: {
            date: yesterday(),
          },
          cancelToken: this.tokenSource.token,
          bustCache: true,
        }),
        server.get("banners/current/DASHBOARD", {
          params: { limit: 3 },
          cancelToken: this.tokenSource.token,
        }),
      ])
      .then(
        ([
          { data: checkInToday },
          { data: checkInYesterday },
          { data: bannerData },
        ]) => {
          this.setState({
            loading: false,
            checkInToday,
            checkInYesterday,
            bannerData,
          })
        },
        this.handlerError
      )
  }

  componentWillUnmount() {
    this.tokenSource.cancel()
  }

  handleError = (err) => {
    if (server.isCancel(err)) return

    this.setState({ error: true, loading: false })
  }

  updateContext = () => {
    const { getPoints, getStreakStatus, getRxStreak, getDrawings } = this.props
    // adherence changes everything!
    getDrawings()
    getPoints()
    getRxStreak()
    getStreakStatus()
  }

  report(adherenceDate, day) {
    server
      .post(
        `adherences/self-reports/prescriptions/${this.props.primaryRx.id}`,
        {
          taken: true,
          adherenceDate,
        }
      )
      .then(({ data }) => {
        this.setState(
          {
            loading: false,
            [`reported${day}`]: data,
          },
          this.updateContext
        )
      }, this.handleError)
  }

  optOut = ({ target }) => {
    if (target.name === "off") {
      this.setState({ optOutReceived: true })
    } else {
      this.setState({
        [target.name === "Today" ? "optOutToday" : "checkInYesterday"]:
          target.name === "Today",
      })
    }
  }

  reportToday = () => this.report(today(), "Today")
  reportYesterday = () => this.report(yesterday(), "Yesterday")

  receivePoints = () => this.setState({ receivedPoints: true })

  introFlowActive(state) {
    const {
      mallOptOut,
      optOutReceived,
      optOutToday,
      points,
      receivedPoints,
      reportedToday,
      reportedYesterday,
    } = state
    return (
      this.adherenceNotification(state) ||
      (optOutToday && !optOutReceived) ||
      ((reportedToday || reportedYesterday) && !receivedPoints) ||
      (points.atMaxPoints && !mallOptOut)
    )
  }

  adherenceNotification({
    checkInToday,
    checkInYesterday,
    optOutToday,
    primaryStatus,
    quizAvailable,
    reportedToday,
    reportedYesterday,
    surveyAvailable,
    verificationOptOut,
  }) {
    return (
      (!primaryStatus.verified && !verificationOptOut) ||
      (checkInToday && !reportedToday && !optOutToday) ||
      (checkInYesterday && !reportedYesterday) ||
      quizAvailable ||
      surveyAvailable
    )
  }

  render() {
    if (this.state.error) {
      return "Something went wrong"
    } else if (this.state.loading) {
      return "Loading..."
    } else {
      // app-context overrides these local values to reflect user actions via past instances:
      // verification and max points goto mall opt out, but not adherence opt out.
      const Component = this.props.component
      const state = { ...this.state, ...this.props }
      return (
        <Component
          reportToday={this.reportToday}
          reportYesterday={this.reportYesterday}
          receivePoints={this.receivePoints}
          optOut={this.optOut}
          introFlowActive={this.introFlowActive(state)}
          adherenceNotification={this.adherenceNotification(state)}
          {...state}
        />
      )
    }
  }
}

export default withAppState(withStreakState(DashboardCarouselData))
