import React, { PureComponent } from "react"
import Auth from "lib/auth"
import { Redirect, withRouter } from "react-router-dom"
import { getListenerOptions } from "lib/domUtils"
import { toPublicRoute } from "components/PrivateRoute/PrivateRoute"

const IDLE_LIMIT = 15 * 60 * 1000
const EVENTS = ["mousemove", "mousedown", "keydown", "scroll"]

const defaultOptions = { capture: true }

export class AutoSignOut extends PureComponent {
  state = {
    redirect: false,
  }

  countdown = null

  componentDidMount() {
    this.attachEvents()
    this.setCountdown()
  }

  componentWillUnmount() {
    this.removeEvents()
    clearTimeout(this.countdown)
  }

  attachEvents() {
    EVENTS.forEach((e) => {
      window.addEventListener(
        e,
        this.registerAction,
        getListenerOptions(defaultOptions)
      )
    })
  }

  removeEvents() {
    EVENTS.forEach((e) => {
      window.removeEventListener(e, this.registerAction, true)
    })
  }

  registerAction = () => {
    clearTimeout(this.countdown)
    this.setCountdown()
  }

  setCountdown() {
    this.countdown = setTimeout(this.outOfTime, IDLE_LIMIT)
  }

  outOfTime = () => {
    Auth.clearSession()
    this.setState({ redirect: true })
  }

  render() {
    const { pathname } = this.props.location
    return (
      this.state.redirect && (
        <Redirect
          to={{
            pathname: toPublicRoute(pathname),
            state: {
              pathname,
              autoLogged: true,
            },
          }}
        />
      )
    )
  }
}

export default withRouter(AutoSignOut)
