import React, { PureComponent } from "react"

import { businessUtils, objUtils, withApi } from "component-library"
import SegmentLogos from "components/SegmentLogos/SegmentLogos"
import SignUpStepOne from "./SignUpStepOne"
import SignUpStepTwo from "./SignUpStepTwo"

import S from "./SignUp.css"
export class SignUp extends PureComponent {
  state = {
    validationErrors: {
      email: false,
      password: false,
      dob: false,
    },
    stepOne: true,
    agreeToTerms: false,
    dateOfBirth: "",
    email: "",
    firstName: "",
    onFederalInsurancePlan: true,
    password: "",
    residentOfStateWithStateInsurancePlan: true,
    medicationId: null,
  }

  componentDidUpdate() {
    const { Auth, createUserSuccess, history } = this.props
    if (createUserSuccess) {
      const { auth0Data, userPointsResultDto } = this.props.createUserData

      Auth.setSession(objUtils.snakeToCamelKeys(auth0Data))
      history.push("/hp/dashboard", {
        virginLogin: true,
        ...userPointsResultDto,
      })
    }
  }

  updateForm = ({ target: { checked, name, type, value } }) => {
    const { validDob, validEmail, passwordStrength } = businessUtils

    if (name === "dateOfBirth") {
      this.setState({ validationErrors: { dob: value && !validDob(value) } })
    } else if (name === "email") {
      this.setState({ validationErrors: { email: !validEmail(value) } })
    } else if (name === "password") {
      this.setState({
        validationErrors: {
          password: passwordStrength(value) < 3 && value && value.length > 6,
        },
      })
    }

    if (
      name === "onFederalInsurancePlan" ||
      name === "residentOfStateWithStateInsurancePlan"
    ) {
      checked = !checked
    }

    this.setState({ [name]: type === "checkbox" ? checked : value })
  }

  sendForm = (e) => {
    e.preventDefault()
    const { validationErrors, stepOne, ...form } = this.state
    this.props.createUser(form)
  }

  gotoStepTwo = (e) => {
    e.preventDefault()
    this.setState({ stepOne: false })
  }

  render() {
    return (
      <div>
        <SegmentLogos />
        <div className={S.signUpContainer}>
          {this.state.stepOne ? (
            <SignUpStepOne
              {...this.state}
              content={this.props.content}
              validEmail={businessUtils.validEmail}
              showSignIn={this.props.showSignIn}
              onChange={this.updateForm}
              onSubmit={this.gotoStepTwo}
            />
          ) : (
            <SignUpStepTwo
              {...this.state}
              {...this.props}
              onChange={this.updateForm}
              onSubmit={this.sendForm}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withApi(SignUp, [
  {
    name: "createUser",
    path: `${PUBLIC_API_URL}/users`,
    action: "post",
  },
])
