import React, { PureComponent } from "react"
import Auth from "lib/auth"

import { Link } from "react-router-dom"
import { Modal, VerificationModal } from "component-library"
import { Icon } from "icon-library"
import { withContent } from "content-library"
import HeaderText from "./HeaderText"
import Logo from "./Logo"
import Settings from "./Settings"
import DesktopIconLink from "./DesktopIconLink"

const LINKS = [
  "/hp/quiz",
  "/hp/survey",
  "/hp/daily-tip",
  "/hp/rewards-mall",
  "/hp/rules",
  "/hp/support",
]

const VerificationModal_ = withContent(VerificationModal, "VerificationModal")

import S from "./PrivateDesktopHeader.css"
import { home, icon, navLink } from "./DesktopIconLink.css"
import brandBar from "./images/branding_bar.png"
import bayerLogo from "images/logo_bayer.png"
export class PrivateDesktopHeader extends PureComponent {
  state = {
    verificationModal: false,
  }

  onModalClose = () => this.setState({ verificationModal: false })

  gotoMall = (e) => {
    if (this.props.primaryStatus.verified) return

    // cancels react-router-dom's action
    e.preventDefault()
    this.setState({ verificationModal: true })
  }

  renderIconLink = (s, i) => (
    <DesktopIconLink key={i} to={s} gotoMall={this.gotoMall} {...this.props} />
  )

  renderTopRow = () => (
    <div className={S.topRow}>
      <HeaderText primaryRx={this.props.primaryRx} />
      <div className={S.settingsContainer}>
        <Settings
          Auth={Auth}
          setContext={this.props.setContext}
          open={this.props.showHeaderSubMenu}
          className={S.topRight}
          firstName={this.props.user.firstName}
        />
        <img src={bayerLogo} />
      </div>
    </div>
  )

  renderBottomRow = () => (
    <div className={S.bottomRow}>
      <Logo
        to="/hp/dashboard"
        history={this.props.history}
        primaryRx={this.props.primaryRx}
      />
      <div className={S.iconLinks}>
        {this.props.location.pathname !== "/hp/dashboard" && (
          <Link className={navLink} to="/hp/dashboard">
            <Icon name="nav_home" className={`${icon} ${home}`} />
            Home
          </Link>
        )}
        {LINKS.map(this.renderIconLink)}
      </div>
    </div>
  )

  render() {
    return (
      <div className={S.headerContent}>
        <div className={S.brandBar}>
          <img src={brandBar} />
        </div>
        {this.renderTopRow()}
        {this.renderBottomRow()}
        {this.state.verificationModal && (
          <Modal closeButton onClose={this.onModalClose}>
            <VerificationModal_ img={this.props.modalImg} />
          </Modal>
        )}
      </div>
    )
  }
}

export default PrivateDesktopHeader
