import React, { Fragment, PureComponent } from "react"

import {
  LeaderboardWidget,
  MobileScorecard,
  RewardsCarousel,
  RxVerificationWidget,
  WeeklyDrawing,
} from "component-library"
import DashboardCarouselData from "./DashboardCarouselData"
import MobileDashboardCarousel from "./MobileDashboardCarousel"

export class MobileDashContent extends PureComponent {
  render() {
    const { content: C } = this.props
    return (
      <Fragment>
        <DashboardCarouselData
          component={MobileDashboardCarousel}
          content={C.IntroFlow}
        />
        <RxVerificationWidget content={C.RxVerificationWidget} />
        <MobileScorecard content={C} />
        <LeaderboardWidget content={C.LeaderboardWidget} />
        <WeeklyDrawing content={C.WeeklyDrawing} />
        <RewardsCarousel content={C.RewardsCarousel} />
      </Fragment>
    )
  }
}

export default MobileDashContent
