import React, { PureComponent } from "react"

import { Select, renderUtils, stringUtils } from "component-library"

const charityChapterId = (cs) => (cs.length !== 0 ? cs[0].id : null)

const HACK_splitDescription = (s) => {
  return s.split("{{DISCLAIMER}}")
}

import S from "./RewardsMallProduct.css"
export class RewardsMallProduct extends PureComponent {
  static defaultProps = {
    className: "",
  }

  state = {
    description: false,
    charityId: charityChapterId(this.props.data.charityChapters),
  }

  orderProduct = () => this.props.orderProduct(this.state.charityId)

  onChange = ({ target }) => this.setState({ charityId: target.value })

  renderSelect = () => (
    <Select
      className={S.select}
      value={this.state.charityId}
      onChange={this.onChange}
    >
      {this.props.data.charityChapters.map(renderUtils.option)}
    </Select>
  )

  toggleDescription = () =>
    this.setState({ description: !this.state.description })

  renderDescription = ([description, disclaimer], C) => (
    <div className={S.description}>
      <p>
        <span className={S.textHeader}>
          <C.descHeading />
        </span>
        <br />
        {description}
        <br />
        <span className={S.textHeader}>
          <C.disclaimerHeading />
        </span>
        <br />
        {disclaimer}
      </p>
      <button className={S.close} onClick={this.toggleDescription}>
        <C.closeMoreBtn />
      </button>
    </div>
  )

  render() {
    const { content: C } = this.props
    const { id, imageUrl, points, title } = this.props.data
    const btnStyle = this.state.description ? S.active : S.inactive
    return (
      <div className={`${S.comp} ${this.props.className}`}>
        <div className={S.imgContainer}>
          <img src={imageUrl} className={S.img} />
        </div>
        <div className={S.meta}>
          <div className={S.title}>{title}</div>
          <div className={S.points}>
            {points.toLocaleString()} point
            {stringUtils.plural(points)}
          </div>
          {this.state.charityId && this.renderSelect()}
          <div className={S.buttons}>
            <button className={btnStyle} onClick={this.toggleDescription}>
              <C.moreBtn />
            </button>
            <button name={id} onClick={this.orderProduct}>
              <C.redeemBtn />
            </button>
          </div>
          {this.state.description &&
            this.renderDescription(
              HACK_splitDescription(this.props.data.description),
              C
            )}
        </div>
      </div>
    )
  }
}

export default RewardsMallProduct
