import React from "react"
import { NavLink } from "react-router-dom"
import { stringUtils } from "component-library"

import { Icon } from "icon-library"

export const ICONS = {
  "/hp/support": "support",
  "/hp/rules": "rules",
  "/hp/rewards-mall": "mall",
  "/hp/medicine-cabinet": "rx",
  "/hp/daily-tip": "daily_tip",
  "/hp/survey": "survey",
  "/hp/quiz": "quiz",
}

const { reverseKebabCase, capitalize } = stringUtils
const noop = () => {}

import S from "./DesktopIconLink.css"
export default function DesktopIconLink({
  articleAvailable,
  gotoMall,
  location,
  quizAvailable,
  surveyAvailable,
  to,
}) {
  const activePath = location.pathname

  const tipMark =
    to === "/hp/daily-tip" && activePath !== "/hp/daily-tip" && articleAvailable

  const quizMark =
    to === "/hp/quiz" && activePath !== "/hp/quiz" && quizAvailable

  const surveyMark =
    to === "/hp/survey" && activePath !== "/hp/survey" && surveyAvailable

  const name = ICONS[to]
  return (
    <NavLink
      className={S.navLink}
      activeClassName={S.active}
      to={to}
      onClick={to === "/hp/rewards-mall" ? gotoMall : noop}
    >
      <Icon name={`nav_${name}`} className={`${S.icon} ${S[name]}`} />
      <span>{capitalize(reverseKebabCase(to.slice(4)))}</span>
      {(tipMark || quizMark || surveyMark) && (
        <span className={S.circle}>
          <Icon name="notification" className={S.noti} />
        </span>
      )}
    </NavLink>
  )
}
