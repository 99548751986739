import React, { Fragment, PureComponent } from "react"

import { withApi } from "component-library"
import RewardsMallHeader from "./RewardsMallHeader"
import RewardsMallPopUp from "./RewardsMallPopUp"
import RewardsMallProduct from "./RewardsMallProduct"
import RewardsMallConfirmation from "./RewardsMallConfirmation"

import S from "./RewardsMall.css"
export class RewardsMall extends PureComponent {
  state = {
    view: "renderMall",
    product: null,
    charityId: null,
    popupView: null,
    orderSent: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const orderSuccess = nextProps.orderProductSuccess && prevState.orderSent
    if (orderSuccess && !prevState.popupView) {
      return {
        view: "renderMall",
        popupView: "Success",
        charityId: null,
      }
    }
    return null
  }

  orderProduct = () => {
    this.setState({ orderSent: true })

    let cId = this.state.charityId
    cId = cId === null ? {} : { charityChapterId: cId }
    this.props.orderProduct({
      ...cId,
      pricedProductId: this.state.product.id,
    })
  }

  closePopup = () =>
    this.setState({ popupView: null, orderSent: false, product: null })

  cancelProduct = () =>
    this.setState({
      view: "renderMall",
      product: null,
      charityId: null,
      popupView: "Cancel",
    })

  renderConfirmation = () => (
    <RewardsMallConfirmation
      content={this.props.content.RewardsMallConfirmation}
      error={this.props.orderProductError}
      order={this.orderProduct}
      cancel={this.cancelProduct}
      data={this.state.product}
    />
  )

  renderMall = () => {
    const { content } = this.props
    const C = content.RewardsMall
    return (
      <Fragment>
        <RewardsMallHeader
          content={content.RewardsMallHeader}
          categories={this.props.getCategoriesData}
          getRewards={this.props.getRewards}
        />
        <div className={S.products}>
          {this.props.getRewardsLoading
            ? "Loading..."
            : this.props.getRewardsData.map(this.renderReward)}
        </div>
        <footer className={S.footer}>
          <C.footer />
        </footer>
      </Fragment>
    )
  }

  renderReward = (product) => (
    <RewardsMallProduct
      content={this.props.content.RewardsMallProduct}
      key={product.id}
      className={S.product}
      orderProduct={(charityId) => {
        if (this.props.points.balance < product.points) {
          return this.setState({ popupView: "NotEnoughPoints" })
        }

        this.setState({
          view: "renderConfirmation",
          product,
          charityId,
        })
      }}
      data={product}
    />
  )

  render() {
    const {
      content,
      getUserLoading,
      getCategoriesLoading,
      orderProductError,
    } = this.props
    if (this.props.error && !orderProductError) {
      return <div>Something went wrong.</div>
    } else if (getUserLoading || getCategoriesLoading) {
      return <div>Loading...</div>
    }
    return (
      <div className={S.comp}>
        {this.state.popupView && (
          <RewardsMallPopUp
            content={content.RewardsMallPopUp}
            view={this.state.popupView}
            close={this.closePopup}
            product={this.state.product}
          />
        )}
        {this[this.state.view]()}
      </div>
    )
  }
}

export default withApi(RewardsMall, [
  { name: "getRewards", path: "rewards/products" },
  {
    name: "orderProduct",
    path: "rewards/order",
    action: "post",
    bustCache: true,
  },
  {
    name: "getUser",
    path: "users/current",
    transform: (user) => ({ user }),
  },
  {
    name: "getCategories",
    path: "categories/public/program",
    params: { params: { categoryType: "REWARDS_MALL" } },
  },
])
