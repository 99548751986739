import React, { Fragment, PureComponent } from "react"
import moment from "moment"

import { Link } from "react-router-dom"

import S from "./Footer.css"
export class Footer extends PureComponent {
  static defaultProps = {
    className: "",
    natazia: false,
  }

  render() {
    const { content, landing, primaryRx } = this.props
    let segmentName

    let ISI
    let RxInfo
    let SideEffects
    let Trademark
    let SegmentName

    if (primaryRx) {
      segmentName = primaryRx.medicationDto.name

      // right of the OR to facilitate dev when primaryRx has a dummy value
      ISI = content[`isi${segmentName}`] || content.isiNatazia
      ISI = <ISI />
      RxInfo =
        content[`prescribingInfo${segmentName}`] ||
        content.prescribingInfoNatazia
      SideEffects = content.reportEffects
      Trademark = content.trademark
      SegmentName = () => `and ${segmentName} `
    } else {
      const { PublicFooter, PrivateFooter } = content

      ISI = (
        <Fragment>
          <PublicFooter.isi />
          <PrivateFooter.isiNatazia />
        </Fragment>
      )
      RxInfo = PublicFooter.prescribingInfo
      SideEffects = PrivateFooter.reportEffects
      Trademark = PrivateFooter.trademark
      SegmentName = ({ text }) => text
    }
    return (
      <Fragment>
        <footer id="footer" className={S.footer}>
          <div className={S.footerContent}>
            {ISI}
            <div className={S.links}>
              <Link to={`/${landing ? "" : "hp/"}rules`}>Rules</Link>
              <Link to={`/${landing ? "" : "hp/"}support`}>FAQ</Link>
              <Link to={`/${landing ? "" : "hp/"}terms`}>Terms of Use</Link>
              <Link to={`/${landing ? "" : "hp/"}privacy`}>Privacy Policy</Link>
            </div>
            <p className={S.eLinks}>
              <RxInfo />
            </p>
            <p className={S.eLinks}>
              <SideEffects />
            </p>
            <p>
              <Trademark insertSegmentName={SegmentName} />
            </p>
            <p>
              Site Last Modified: {moment(TIME_AT_COMPILE).format("MM/YYYY")}
            </p>
            <p>
              <span className={S.copyright}>© 2018 Bayer</span>
              All Rights Reserved.
            </p>
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default Footer
