import React, { Fragment, PureComponent } from "react"
import Auth from "lib/auth"

import { Icon } from "icon-library"
import { Modal, VerificationModal } from "component-library"
import { withContent } from "content-library"
import HeaderText from "./HeaderText"
import Logo from "./Logo"
import MobileIconLink from "./MobileIconLink"

const LINKS = [
  "/hp/dashboard",
  "/hp/monthly-points",
  "/hp/quiz",
  "/hp/survey",
  "/hp/daily-tip",
  "/hp/rewards-mall",
  "/hp/rules",
  "/hp/support",
  "/hp/prescription",
  "/hp/profile",
  "/hp/communication",
  "/",
]

const VerificationModal_ = withContent(VerificationModal, "VerificationModal")

import S from "./PrivateMobileHeader.css"
import brandBar from "./images/branding_bar_mobile.png"
import bayerLogo from "images/logo_bayer.png"
export class PrivateMobileHeader extends PureComponent {
  state = {
    open: false,
    verificationModal: false,
  }

  onModalClose = () => this.setState({ verificationModal: false })

  gotoMall = (e) => {
    if (this.props.primaryStatus.verified) return

    // cancels react-router-dom's action
    e.preventDefault()
    this.setState({ verificationModal: true })
  }

  toggle = () => {
    this.setState({ open: !this.state.open }, () => {
      this.props.setOverride(this.state.open)
    })
  }

  renderIconLink = (s, i) => (
    <MobileIconLink
      onClick={this.toggle}
      key={i}
      to={s}
      {...this.props}
      logout={Auth.clearSession}
      gotoMall={this.gotoMall}
    />
  )

  renderTopRow = () => (
    <div className={S.topRow}>
      <HeaderText primaryRx={this.props.primaryRx} />
      <img src={bayerLogo} />
    </div>
  )

  renderBottomRow = () => (
    <div className={S.bottomRow}>
      <div className={S.pointContainer}>
        <span className={S.points}>
          {this.props.points.balance.toLocaleString()}
        </span>
        <span className={S.label}>Points</span>
      </div>
      {this.props.points.atMaxPoints && (
        <Icon name="max" className={S.maxIcon} />
      )}
      <button
        className={`${S.toggleBtn} ${this.state.open ? S.toggleActive : ""}`}
        onClick={this.toggle}
      >
        <Icon name="menu" className={S.menu} />
        {(this.props.articleAvailable ||
          this.props.quizAvailable ||
          this.props.surveyAvailable) && (
          <span className={S.circle}>
            <Icon name="notification" className={S.noti} />
          </span>
        )}
      </button>
    </div>
  )

  renderLinks = () => (
    <Fragment>
      <div
        style={{ top: this.props.dropdownTop }}
        className={S.dropdownContainer}
      >
        <div className={S.dropdown}>{LINKS.map(this.renderIconLink)}</div>
      </div>
      <div className={S.backdrop} />
    </Fragment>
  )

  render() {
    return (
      <div ref={this.headerRef} className={S.headerContent}>
        <img src={brandBar} onLoad={this.props.updateHeaderHeight} />
        {this.renderTopRow()}
        <Logo
          className={S.logo}
          to="/hp/dashboard"
          history={this.props.history}
          primaryRx={this.props.primaryRx}
        />
        {this.renderBottomRow()}
        {this.state.open && this.renderLinks()}
        {this.state.verificationModal && (
          <Modal closeButton onClose={this.onModalClose}>
            <VerificationModal_ img={this.props.modalImg} />
          </Modal>
        )}
      </div>
    )
  }
}

export default PrivateMobileHeader
